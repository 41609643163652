<div class="container-fluid p-4">
  <h2>add new {{ convertRoleName(this.data.role) }}</h2>
  <div>
    <app-autocomplete
    label="Please enter a name"
    labelClass="custom-label-styling"
    #toAutocomplete
    [allowMultipleSelection]="false"
    [clearOnOptionSelect]="false"
    (selectedItemEvent)="getUserInfo($event)"
  ></app-autocomplete>
    <mat-form-field appearance="outline" *ngIf="availableTriggerValues.length > 0" class="triggerValue">
      <mat-select (selectionChange)="onValueChange($event)" [(ngModel)]="currentTriggerValue" [(value)]="currentTriggerValue" name="val" placeholder="Please select one">
        <mat-option
          *ngFor="let val of availableTriggerValues"
          [value]="val.Code"
          >{{ val.Description }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <label *ngIf="hasSubGroup"> Based on the selected {{this.data.approvalTrigger}} - select a corresponding sub{{this.data.approvalTrigger}}.</label>
    <mat-form-field appearance="outline" *ngIf="hasSubGroup" class="triggerValue">
      <mat-select [(ngModel)]="subGroupTriggerValue" [(value)]="subGroupTriggerValue" name="group" placeholder="Please select one">
        <mat-option
          *ngFor="let group of subGroups"
          [value]="group.Code"
          >{{ group.Description }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="d-flex flex-row justify-content-end mt-4" style="gap: 1rem">
    <button
      type="button"
      mat-button
      class="btn"
      (click)="cancel()"
      id="cancelButton"
    >
      cancel
    </button>
    <button class="btn btn-primary" id="submitButton" (click)="submit()">
      submit
    </button>
  </div>
</div>
