import {
  Component,
  OnInit,
  Inject,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import { CreateEditBulkLessonService } from "src/app/core/services/lesson-services/create-edit-bulk-lesson.service";
import { ColumnSettingComponent } from "../column-setting/column-setting.component";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { DialogMessageComponent } from "../dialog-message/dialog-message.component";
import { ToastService } from "src/app/core/services/toast.service";
import { MatPaginator } from "@angular/material/paginator";
import { Lesson, User } from "src/app/shared/models";
import { unique } from "jquery";

@Component({
  selector: "app-bulk-edit-lesson",
  templateUrl: "./bulk-edit-lesson.component.html",
  styleUrls: ["./bulk-edit-lesson.component.scss"],
})
export class BulkEditLessonComponent
  implements OnInit, AfterViewInit, OnDestroy
{

  technicalApprovers: User[] = [];
  constructor(
    public singleLessonService: CreateEditSingleLessonService,
    public bulkEditService: CreateEditBulkLessonService,
    public dialogRef: MatDialogRef<BulkEditLessonComponent>,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public commonService: CommonService,
    public toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public async ngOnInit() {
    this.bulkEditService.showSubmit = false;
    this.bulkEditService.title = this.data.title;
    this.bulkEditService.lessons = this.data.lessons;
    this.getTechnicalApprovers(this.bulkEditService.lessons);
    this.bulkEditService.dialogueRef = this.dialogRef;
    this.bulkEditService.onInit();
  }

  ngAfterViewInit(): void {
    this.bulkEditService.loadMatTable(this.bulkEditService.lessons, false);
    this.bulkEditService.dataSource.paginator = this.paginator;
    this.detectChangesinComp();
  }


  getTechnicalApprovers(lessons: Lesson[]){
    lessons.forEach(lesson => {
      lesson.approverTechnicals.forEach(approver => {
        this.technicalApprovers.push(approver);

      })
    })
    const uniqueApprovers = new Map();
    this.technicalApprovers.forEach(approver => {
      uniqueApprovers.set(approver.fullName, approver);
    })
    this.technicalApprovers = Array.from(uniqueApprovers.values());

    console.log(this.technicalApprovers)
    
  }

  public confirmSubmitDialog(selectedAction: string) {
    //To populate transitionName and pending status for lesson
    this.bulkEditService.lessons[0].transitionName = selectedAction;
    const res = this.bulkEditService.onTransitionNameChanged(selectedAction);
    if (!res) return;
    const body = `are you sure you want to submit?`;
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      data: { title: "create new lesson", body },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.bulkEditService.saveOrSubmitLessons(true);
      }
    });
  }

  detectChangesinComp() {
    this.cdr.detectChanges();
  }
yo
  openColumnSettingDialog(columnIndex) {
    const dialogRef = this.dialog.open(ColumnSettingComponent, {
      autoFocus: false,
      height: "15rem", // you can adjust the value as per your view,
      data: {
        editableFieldByStatus:
          this.bulkEditService.visibleFieldsByStatus.sort((a, b) => a.localeCompare(b)),
        selectedColumns: this.bulkEditService.selectedColumns,
        columnIndex: columnIndex,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.bulkEditService.selectedColumns = result.selectedColumns;
        this.bulkEditService.loadLessonFields();
        this.bulkEditService.columnsToDisplay = [
          "select",
          "id",
          ...this.bulkEditService.selectedColumns,
        ];
      }
    });
  }

  getLessonIndex(i: number) {
    return i + this.paginator.pageIndex * this.paginator.pageSize;
  }

  public ngOnDestroy(): void {
    this.bulkEditService.onDestroy();
  }
}
