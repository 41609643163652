import { Injectable } from "@angular/core";
import { CreateEditSingleLessonService } from "src/app/core/services/lesson-services/create-edit-single-lesson.service";
import {
  Lesson,
  LessonFieldUpdate,
  User,
  UserGroupList,
  LessonComment,
} from "src/app/shared/models";
import { MatTableDataSource } from "@angular/material/table";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { SelectionModel } from "@angular/cdk/collections";
import { MsGraphService } from "../../http/ms-graph.service";
import { LessonService } from "../../http/lesson.service";
import { ToastService } from "../toast.service";
import { HttpErrorResponse } from "@angular/common/http";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { WorkflowConfigModel } from "src/app/shared/models/configsV2/workflowConfigModel";
import { EvalconfigService } from "./eval-config.service";
import { firstValueFrom, Subscription } from "rxjs";
import { bulkWorkflowConfiguration } from "src/app/shared/models/configsV2/bulkEditConfigModel";

@Injectable({
  providedIn: "root",
})
export class CreateEditBulkLessonService {
  title: string = "";
  lessons: Lesson[] = [];
  lessonsCount: number;
  lessonsStatus: string;
  columnsToDisplay: string[] = [];
  dataSource: any = null;
  selection = new SelectionModel<Lesson>(true, []);
  checkboxColor: string = "primary";
  lessonsFormGroup: FormGroup;
  approverActionRequests: Array<string> = [];
  lessonNextSteps: WorkflowConfigModel[] = [];
  groups = new UserGroupList();
  disableSubmit: boolean;
  disableOverrideData: boolean = true;
  show: boolean;
  dialogueRef: any;
  selectedColumns: string[] = [];
  comment: string;
  userProfile: User;
  columnSettingToolTip: string = "click here to change the column settings";
  showSubmit: boolean;
  errorCount: number = 0;
  public showCopSubGroup: boolean = false;

  public lessonComments: LessonComment[] = [];

  public visibleBulkFieldItems: any[] = []; //visible fields items along with groups for bulk edit
  public visibleLessonFields: any[] = [];
  public visibleFieldsByStatus: string[] = [];
  public updatedLessonFields: string[] = [];
  public workflowConfig: bulkWorkflowConfiguration;

  public allowedControlTypes: string[] = [
    "Input",
    "MatSelectMultiple",
    "MatSelectSingle",
    "MatSlideToggle",
    "MatAutoComplete",
  ];
  public subscriptions: Subscription = new Subscription();
  constructor(
    public singleLessonService: CreateEditSingleLessonService,
    public fb: FormBuilder,
    public graphApi: MsGraphService,
    public lessonService: LessonService,
    private toastService: ToastService,
    public commonService: CommonService,
    public evalService: EvalconfigService
  ) {}

  async onInit() {
    this.showSubmit = false;
    this.show = false;
    this.lessonsCount = this.lessons.length;
    this.lessonsStatus = this.lessons[0].status;
    this.singleLessonService.onInit();
    this.loadConfigs();
    this.loadDefaultValues();
    this.buildFormArray();
    this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
  }

  public loadDefaultValues() {
    const lessonWorkflowType = this.lessons[0].lessonWorkflowType;
    this.workflowConfig = this.commonService.bulkEditConfig[lessonWorkflowType];
    this.selectedColumns = this.workflowConfig?.defaultVisibleFields ?? [];
    this.columnsToDisplay = ["select", ...this.selectedColumns];
    this.visibleBulkFieldItems = [];
    this.commonService.visibleFieldItems.forEach((x) => {
      const itemValues = [];
      x.value.forEach((y) => {
        if (this.workflowConfig.bulkVisibleFields.indexOf(y.key) != -1)
          itemValues.push(y);
      });
      if (itemValues.length)
        this.visibleBulkFieldItems.push({ key: x.key, value: itemValues });
    });
    this.loadLessonFields();
  }

  public loadConfigs() {
    this.commonService.loadConfig();
    this.commonService.loadGroups();
    this.commonService.loadFieldConfigurations();
    this.commonService.loadFieldsStatus(this.lessons[0]);
    this.commonService.loadVisibleItemGroupValues(this.lessons[0].status);
    this.evalService.loadLessonNextSteps(this.lessons[0]);
    this.singleLessonService.lesson = this.lessons[0];
    this.singleLessonService.checkCrossVisibilityConfig();
  }

  loadLessonFields() {
    this.visibleFieldsByStatus = this.commonService.visibleFieldsByStatus
      .find((x) => x.key === this.lessons[0].status)
      .value.filter((o) => this.workflowConfig.columnFields.indexOf(o) != -1);

    this.visibleLessonFields = this.commonService.visibleFieldItems.map((x) => {
      return {
        ...x,
        value: x.value.filter((y) => this.selectedColumns.indexOf(y.key) != -1),
      };
    });
  }

  isFieldEditable(fieldName: any): boolean {
    if (this.workflowConfig.readOnlyFields.indexOf(fieldName) != -1)
      return false;
    else
      return this.commonService.isFieldEditable(
        this.lessons[0].isLessonEditable,
        this.lessonsStatus,
        fieldName
      );
  }

  getListFromReferenceConfig(field: string) {
    return this.commonService.getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
      "Test",
      field,
      this.lessons[0].lessonWorkflowType
    );
  }

  loadMatTable(lessons: Lesson[], ifValidationExceptionErrors: boolean) {
    if (ifValidationExceptionErrors) {
      if (this.columnsToDisplay.indexOf("validationException") < 0) {
        this.columnsToDisplay.push("validationException");
      }
      if (this.selectedColumns.indexOf("validationException") < 0) {
        this.selectedColumns.push("validationException");
      }
      const filteredValue = this.visibleLessonFields[0].value.filter(
        (o) => o.key == "validationException"
      );
      if (filteredValue.length == 0)
        this.visibleLessonFields[0].value.push({
          key: "validationException",
          value:
            this.commonService.fieldConfigurations["corePrimaryInfoGroup"][
              "validationException"
            ],
        });
    }
    this.dataSource = new MatTableDataSource(lessons);
    this.lessonsCount = lessons.length;
    this.show = true;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** Lesson Form array and validators check */
  buildFormArray() {
    this.lessonsFormGroup = this.commonService.getLessonForm(
      this.lessons[0].status,
      this.lessons[0],
      this.visibleBulkFieldItems
    );
    this.lessonsFormGroup.addControl(
      "comment",
      new FormControl("", Validators.nullValidator)
    );
    this.lessonsFormGroup.addControl("lessonsFormArray", this.fb.array([]));
    const lessonsFormArrayTable = this.lessonsFormGroup.get(
      "lessonsFormArray"
    ) as FormArray;
    const visibleFieldItems = this.commonService.visibleFieldItems.map((x) => {
      return {
        ...x,
        value: x.value.filter(
          (y) => this.workflowConfig.columnFields.indexOf(y.key) != -1
        ),
      };
    });
    this.lessons.forEach((lesson) => {
      lessonsFormArrayTable.push(
        this.commonService.getLessonForm(
          this.lessons[0].status,
          lesson,
          visibleFieldItems
        )
      );
    });
  }

  get lessonsFormArray() {
    return this.lessonsFormGroup.get("lessonsFormArray") as FormArray;
  }

  formLabel(fieldId: string) {
    //checking in core
    for (const group in this.commonService.fieldConfig.fieldConfig.lessonCore) {
      if (this.commonService.fieldConfig.fieldConfig.lessonCore[group][fieldId]) {
        return this.commonService.fieldConfig.fieldConfig.lessonCore[group][fieldId]
          .feMetadata?.formLabel;
      }
    }
  }

  updateLesson(value: LessonFieldUpdate) {
    let lessonIndex = this.lessons.findIndex((x) => x.id == value.lesson.id);
    this.lessons[lessonIndex][value.fieldId] = value.lesson[value.fieldId];
  }

  commentPostAll() {
    let newComment = new LessonComment();
    newComment.content = this.comment;
    newComment.lastUpdatedUser = this.userProfile;
    newComment.lastUpdatedDate = new Date();
    newComment.isPublic = true;
    newComment.lessonReplies = [];
    this.lessonComments.push(newComment);
    this.comment = "";
    this.singleLessonService.showMessageandHide(
      true,
      "Comments added to all lessons"
    );
  }

  /*Lesson Operations save, submit, validate and discard */
  async saveOrSubmitLessons(isSubmit: boolean) {
    if (!this.lessonsFormGroup.valid) {
      this.toastService.showError(true, "Please fill out all required fields before proceeding");
      return;
    }
    let savedPrevStatus = this.lessons[0].previousStatus;
    let savedStatus = this.lessons[0].status;
    let savedPendingStatus = this.lessons[0].pendingStatus;
    if (isSubmit) {
      const workFlow = this.commonService.workflowConfig[
        this.lessons[0].lessonWorkflowType
      ].filter(
        (s) =>
          s.FromStatus == this.lessons[0].status &&
          s.ToStatus == this.lessons[0].pendingStatus
      );
      const res = await this.settingLessonApprovers();
      if (res) {
        this.toastService.showError(true, "Uh-oh! Looks like there was an error while setting approvers. Please reach out to the support team for further assistance");
        return;
      }
      await this.setLessonFields();
    }
    this.show = false;
    firstValueFrom(
      this.lessonService.bulkUpdateLessons(
        this.lessons,
        this.lessons[0].importId
      )
    ).then(
      (response) => {
        this.showSubmit = true;
        this.errorCount = 0;
        this.singleLessonService.showMessageandHide(
          true,
          "Records updated successfully"
        );
        this.Back();
        this.show = true;
      },
      (error: HttpErrorResponse) => {
        this.showSubmit = false;
        this.show = true;
        this.errorCount = error.error.errorCount;
        this.singleLessonService.showMessageandHide(
          true,
          `Errors found in ${error.error.errorCount}. Please fix them and submit again.`
        );
        this.lessons = error.error.lessons;
        this.lessons.forEach((lesson) => {
          lesson.previousStatus = savedPrevStatus;
          lesson.pendingStatus = savedPendingStatus;
          lesson.status = savedStatus;
          lesson.validationException =
            lesson.validationException == null
              ? ""
              : lesson.validationException;
          if (lesson.status == "draft") {
            lesson.dateOfSubmission = null;
          }
        });
        this.lessons.sort((a, b) =>
          a.validationException.length < b.validationException.length ? 1 : -1
        );
        this.buildFormArray();
        this.loadMatTable(this.lessons, true);
      }
    );
  }

  deleteLessonsInBulk(): void {
    this.show = false;
    const lessonIdsTodelete = this.selection.selected.flatMap(
      (lesson) => lesson.id
    );
    if (lessonIdsTodelete.length == 0) {
      this.toastService.showError(true, 
        "No lessons selected. Please select lessons to discard"
      );
      this.show = true;
      return;
    }
    this.subscriptions.add(
      this.lessonService
        .deleteLessons(lessonIdsTodelete)
        .subscribe((response) => {
          if (response.success) {
            //Deleting all of the lessons in the dialog
            if (
              this.lessons.every((s) =>
                response.lessons.some((t) => t.id == s.id)
              )
            ) {
              this.Back();
              this.singleLessonService.showMessageandHide(
                true,
                "Discarded all lessons"
              );
            }
            //Deleting subset of lessons
            else {
              this.lessons = this.lessons.filter(
                (lesson) => !this.selection.selected.includes(lesson)
              );
              this.loadMatTable(this.lessons, false);
              this.toastService.showSuccess(true, 'Removed lesson(s)');
            }
          }
        })
    );
  }

  Back() {
    this.loadDefaultValues();
    this.loadConfigs();
    this.buildFormArray();
    this.dataSource = null;
    this.dialogueRef.close();
  }

  validateError(row: any) {
    if (
      row.validationException == null ||
      row.validationException == "" ||
      row.validationException == undefined
    )
      return "";
    else return "rowHighlight";
  }

  disabledSubmit() {
    return this.disableSubmit;
  }

  getLessonFieldValue(index: number, field: string) {
    if (this.lessons[index][field]) {
      return this.lessons[index][field];
    } else if (
      this.lessons[index].lessonWorkflowType &&
      this.lessons[index][this.lessons[index].lessonWorkflowType]
    ) {
      return this.lessons[index][this.lessons[index].lessonWorkflowType][field];
    }
  }

  public onValueChange(
    selectedValue: any,
    field: string,
    referenceConfigDraft: any
  ) {
    switch (field) {
      case "transitionName":
        this.onTransitionNameChanged(selectedValue);
        break;
      default:
    }
  }

  public onTransitionNameChanged(selectedTransitionName: any) {
    //change the transitionName and change the pendingStatus accordingly

    if (selectedTransitionName) {
      this.lessons[0].pendingStatus = this.commonService.getPendingStatus(
        selectedTransitionName,
        this.lessons[0].lessonWorkflowType,
        this.lessons[0].status
      );

      if (!this.lessons[0].pendingStatus) {
        this.toastService.showError(true,
          `Looks like we were unable to transition the lesson to status: ${this.lessons[0].pendingStatus} properly. Please reach out to the support team for further assistance.`
        );
        return false;
      }
      return true;
    }
  }

  public updateLessonFields(value: LessonFieldUpdate) {
    if (this.lessons[0][value.fieldId] == undefined) {
      this.lessons[0][this.lessons[0].lessonWorkflowType][value.fieldId] =
        value.lesson[this.lessons[0].lessonWorkflowType][value.fieldId];
    } else this.lessons[0][value.fieldId] = value.lesson[value.fieldId];

    this.updatedLessonFields.push(value.fieldId);
  }

  public async setLessonFields() {
    this.lessons.forEach((x) => {
      x.previousStatus = x.status;
      x.status = x.pendingStatus;
      x.lessonComments = x.lessonComments.concat(this.lessonComments);
      if (!x.dateOfSubmission) x.dateOfSubmission = new Date();
    });
    this.lessonComments = [];
  }

  public async settingLessonApprovers() {
    let errorRes = false;
    for (let x of this.lessons) {
      this.updatedLessonFields.forEach((fieldId: string) => {
        if (x[fieldId] == undefined) {
          x[this.lessons[0].lessonWorkflowType][fieldId] =
            this.lessons[0][this.lessons[0].lessonWorkflowType][fieldId];
        } else x[fieldId] = this.lessons[0][fieldId];
      });
      x.transitionName = this.lessons[0].transitionName;
      x.pendingStatus = this.lessons[0].pendingStatus;
      const result = await this.evalService.setAndValidateTransition(x);
      if (!result) {
        errorRes = true;
        break;
      }
    }
    return errorRes;
  }

  public onDestroy() {
    this.subscriptions.unsubscribe();
  }
}
