<div class="bulkImportContainer">
  <div class="bulkImportHeader" >
    <h2> Import Lessons </h2>
  </div>
  <div *ngIf="showIntro">
    <div class="question">
      <h4> Is this your first time importing lessons? </h4>
    </div>
    <div class="buttons">
        <button
        type="button"
        class="btn btn-primary yesBtn"
        type="button"
        (click)="onYesClick()"
      >
        yes
      </button>
        <button
        type="button"
        class="btn btn-primary noBtn"
        type="button"
        (click)="onNoClick()"
      >
        no
      </button>
    </div>
  </div>
  <div *ngIf="showGuide" style="margin-bottom: 2rem;">
    <h4> No worries! Feel free to take a look at our guide <a href="{{ bulkImportWikiUrl }}" target="_blank">here</a> </h4>
  </div>
  <button *ngIf="showGuide" class="btn btn-primary" (click)="onNoClick()">
    got it
  </button>
  <div class="templates" *ngIf="showTemplates">
    <div class="align-self-center">
      <h4> 1. Select one of our templates below to get started! </h4>
    </div>
    <div class="selectedContainer">
      <mat-form-field appearance="outline" class="templateField">
        <mat-select 
        [(ngModel)]="selectedTemplateType"
        placeholder="Select Template"
        >
          <mat-option *ngFor="let template of coreReferences.DownloadTemplate" [value]="template.Code">
            {{ template.Description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
      *ngIf="selectedTemplateType"
      type="button"
      class="btn btn-primary m-2 btn-md downloadBtn"
      (click)="downloadTemplate()"
      style="margin-bottom: 3rem;"
    >
      download
    </button>
    </div>
  <input
  #fileInputNew
  class="form-control"
  type="file"
  hidden
  accept=".xlsm"
  (change)="onFileSelected($event)"
/>
  <div *ngIf="showInfo">
    <div style="margin-bottom: 3rem;">
      <h4> 2. Fill out the following information to continue </h4>
      <div class="infoContainer">

        <h5> Import Name </h5>
        <span class="width-100">
          <input
          id="fileNickname"
          matInput
          class="input-text"
          type="text"
          [(ngModel)]="fileNickname"
          (ngModelChange)="validateImportInfo()"
        />
        </span>
  
        <h5> Import Description </h5>
      <span class="width-100">
        <textarea
          id="bulkDescription"
          class="input-text"
          matInput
          [(ngModel)]="fileDescription"
          (ngModelChange)="validateImportInfo()"
        ></textarea>
      </span>
      </div>
    </div>
    <div *ngIf="showUpload">
      <h4> 3. Upload your lessons </h4>
      <div class="upload-lesson-border">
        <h4> Please note we ONLY accept .xls files utilizing one of our templates. </h4>
        <div class="uploadBtns">
          <button
            class="btn btn-primary chooseFileBtn"
            (click)="fileInputNew.click()"
            style="color: #fff; margin-bottom: 1rem;"
          >
            select file
          </button>
          <mat-chip-listbox class="fileName" style="margin-bottom: 1rem;">
            <mat-chip-option *ngIf="fileName">
              <img src="../../../../../../assets/images/Excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px;">
              {{ fileName }}
              <mat-icon class=matChipRemove (click)="removeFile()">close</mat-icon>
            </mat-chip-option>
          </mat-chip-listbox>
          
          <button
            *ngIf="file"
            style="color: #fff; margin-bottom: 1rem;"
            [disabled]="!file || !fileNickname"
            (click)="upload()"
            class="btn btn-primary width-100 uploadBtn"
          >
            upload
          </button>
        </div> 
        <div>
          <div *ngIf="file && showDisclaimer">
            <p class="disclaimerText">
              Disclaimer: Lessons uploaded via bulk importing will be placed in the 'draft' status. Please navigate to your actions to transition further.
            </p>
          </div>
        
          <mat-progress-bar
          class="progress-bar"
          mode="indeterminate"
          *ngIf="uploadInProgress"
        >
          </mat-progress-bar>
          <ng-container *ngIf="!uploadInProgress">
            <div *ngIf="importCompleted">
              <p class="successText">
                Bulk upload completed successfully. An e-mail notification will be
                sent when the submission is available in your ‘Actions’.
              </p>
            </div>
          </ng-container>
        </div>
    </div>
    </div>
  </div>
  </div>
</div>