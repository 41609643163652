<table>
  <thead>
    <tr>
      <th>Name</th>
      <th>Email</th>
      <th [ngClass]="!isDiscipline ? 'efforts__hideColumn' : ''">Discipline(s)</th>
      <th [ngClass]="!isTeam ? 'efforts__hideColumn' : ''">Team(s)</th>
      <th [ngClass]="!isAsset ? 'efforts__hideColumn' : ''">Asset(s)</th>
      <th [ngClass]="!isDepartment ? 'efforts__hideColumn' : ''">Department(s)</th>
      <th [ngClass]="!isRelatedProcess ? 'efforts__hideColumn' : ''">Processes</th>
      <th [ngClass]="!isRegion ? 'efforts__hideColumn' : ''">Region(s)</th>
      <th [ngClass]="!isCop ? 'efforts__hideColumn' : ''">CoP(s)</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of dataSource">
      <td>{{ item?.fullName }}</td>
      <td>{{ item?.email }}</td>
      <td [ngClass]="!isDiscipline ? 'efforts__hideColumn' : ''">
        {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </td>
      <td [ngClass]="!isTeam ? 'efforts__hideColumn' : ''">
        {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </td>
      <td [ngClass]="!isAsset ? 'efforts__hideColumn' : ''">
        {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </td>
      <td [ngClass]="!isDepartment ? 'efforts__hideColumn' : ''">
        {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </td>
      <td [ngClass]="!isRelatedProcess ? 'efforts__hideColumn' : ''">
        {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </td>
      <td [ngClass]="!isRegion ? 'efforts__hideColumn' : ''">
        {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </td>
      <td [ngClass]="!isCop? 'efforts__hideColumn' : ''">
        {{ item?.triggerValues ? item.triggerValues.join(', ') : '' }}
      </td>
    </tr>
  </tbody>
</table>