<app-page-header [pageTitle]="pageTitle"></app-page-header>
<!-- Main Content -->
<main class="container-fluid">
  <div class="row d-flex flex-row align-items-center">
    <!-- START SCOPED VIEWS -->
    <div
      class="col-6 px-0 py-0 p-2"
      style="flex: 0 0"
      [ngClass]="{ scopeFilter: searchTutorial.scopeFilter === true }"
    >
      <mat-tab-group
        class="remove-border-bottom"
        [selectedIndex]="selectedTabIndex.value"
        (selectedIndexChange)="selectedTabIndex.setValue($event)"
        (selectedTabChange)="onTabChanged()"
      >
        <mat-tab
          *ngFor="let tab of tabs; let index = index"
          [label]="tab.label"
        >
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- END SCOPED VIEWS -->

    <!-- START SEARCH BAR -->

    <div style="display: flex; align-items: center; justify-content: center;">

    </div>
    <div class="searchBar" style="flex: 1">
      <input
        type="search"
        placeholder="I'm looking for...."
        [(ngModel)]="searchText"
        class="form-control"
        (keydown.enter)="searchLessons()"
      />
      <img class="searchIcon" src="assets/images/search.png" alt="" (click)="searchLessons()"/>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of athenaSearchService.searchSuggestions$ | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <button class="btn btn-primary" id="clearButton" (click)="clearSearchText()">
      clear search
    </button>
  </div>
  <!-- END SEARCH BAR -->

  <div class="row d-flex justify-content-end py-0">
    <div class="col-10">
      <!-- START SORT & PAGE AMOUNT-->
      <mat-form-field
        class="sortBy"
        [ngClass]="{ scopeFilter: searchTutorial.sortFilter === true }"
        appearance="outline"
      >
        <mat-select
          [(ngModel)]="pageSort"
          placeholder="sort by"
          [(value)]="pageSort"
          (selectionChange)="sortByOption()"
          floatlLabel="never"
          id="pageSort"
        >
          <mat-option *ngFor="let sort of sortByoption" [value]="sort.key">{{
            sort.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="itemsPerPage"
        [ngClass]="{ scopeFilter: searchTutorial.sortFilter === true }"
        appearance="outline"
      >
        <mat-select
          placholder="items per page"
          [(ngModel)]="itemsPerPage"
          [(value)]="itemsPerPage"
          (selectionChange)="onItemsPerPageChange()"
          id="itemsPerPage"
        >
          <mat-option
            *ngFor="let perPage of itemsPerPageOptions"
            [value]="perPage.key"
          >
            {{ perPage.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <!-- END SORT & PAGE AMOUNT-->

      <!-- START BULK WF-->
      <button
        *ngIf="selectedTabIndex.value == 2"
        [disabled]="!allLessons.length"
        type="button"
        class="btn btn-outline-primary float-right"
        id="bulkWorkflowButton"
        (click)="bulkEditLesson()"
        matTooltip="To edit Lesson Learned detail, proceed to open Lesson Learned individually.
                    To advance Lesson Learned in workflow without changing detail, meet requirements & select bulk workflow actions"
        matTooltipPosition="below"
      >
        bulk workflow actions
      </button>
      <!-- END BULK WF-->
    </div>
  </div>

  <div class="row">
    <div
      class="col-2 px-3"
      [ngClass]="{ scopeFilter: searchTutorial.facetFilter === true }"
    >
    <div class="filterButtons">
      <button class="btn btn-primary" id="submitButton" (click)="addFacetFilters()" style="margin-bottom: 1rem; width: 9rem; margin-right: 1rem;">
        + filter
      </button>
      <button class="btn btn-primary" id="clearButton" (click)="clearAllFacetFilters()" style="margin-bottom: 1rem; width: 9rem;">
        clear filter(s)
      </button>
    </div>
      <div class="card side-filters">
        <h5 class="py-3">filter by</h5>
        <div
          class="row col-12 mb-0 px-0 py-0"
          *ngFor="let facet of facets; let i = index"
        >
          <mat-expansion-panel [expanded]="facet.expanded">
            <mat-expansion-panel-header>
              <mat-panel-title> {{ facet.label }} </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              class="row form-check"
              *ngFor="
                let selectedFilter of facet.facetValues
                  | slice : 0 : (filterShowMore[i] ? undefined : 7);
                let ix = index
              "
            >
              <ul class="px-0">
                <li>
                  <input
                    #checkboxes
                    class="form-check-input flexCheckDefault"
                    type="checkbox"
                    [(ngModel)]="selectedFilter.selected"
                    (change)="verifyFacet(facet, selectedFilter, $event)"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ selectedFilter.description }} ({{
                      selectedFilter.count ? selectedFilter.count : 0
                    }})
                    <mat-icon
                      *ngIf="
                        selectedFilter.longDescription != '' &&
                        selectedFilter.longDescription
                      "
                      [ngbTooltip]="selectedFilter.longDescription"
                      style="opacity: 0.5"
                      >info
                    </mat-icon>
                  </label>
                </li>
              </ul>
            </div>
            <span
              class="show-more right-align"
              *ngIf="facet.totalCount > 7"
              (click)="filterShowMore[i] = !filterShowMore[i]"
              >{{ filterShowMore[i] ? "less" : "more >" }}</span
            >
          </mat-expansion-panel>
        </div>
      </div>
    </div>

    <div class="col-10" *ngIf="!show">
      <main class="container spinner">
        <mat-spinner></mat-spinner>
      </main>
    </div>
    <div class="col-10 container" *ngIf="show">
      <div class="row px-3 py-2">
        <div class="col-8">
            <mat-chip-listbox #chipList *ngIf="getChips()?.length > 0">
              <mat-chip-option *ngFor="let item of getChips(); let i = index">
                <b> {{ convertFacetLabelName(item.facetLabel) }}:</b> {{ item.description }}
              </mat-chip-option>
            </mat-chip-listbox>
            <label *ngIf="getChips()?.length <= 0" class="filterstext">
              no filters applied
            </label>

        </div>
        <div class="col-4 paginator d-flex justify-content-end">
          <ngb-pagination
            [(page)]="page"
            [maxSize]="1"
            [collectionSize]="items"
            [rotate]="true"
            [pageSize]="itemsPerPage"
            (pageChange)="searchLessons()"
          >
          </ngb-pagination>
        </div>
      </div>

      <div class="row px-3">
        <div class="col-10">
          <p class="resultCount">
            showing {{ allLessons.length }} out of {{ items }} results <span *ngIf="showSearchText">for '{{ searchText }}'</span>
          </p>
        </div>
        <!-- To show cards view of lessons-->
        <div class="col-2 d-flex justify-content-end export">
          <div
            *ngIf="currentLessonsSource == 'ell'"
            class="px-0 py-0 exportText"
            (click)="exportLessonsExcel(allLessons)"
          >
            <span>export lessons</span>
            <img src="assets/icons/32_Export.svg" />
          </div>
        </div>
      </div>

      <h3 *ngIf="allLessons.length == 0" style="margin-top: 5rem;"> 
        Hmmm.. Looks like you don't have any lessons to view/edit here. If this is a mistake, please contact your LL Manager or the content contact:
        <a href="{{ contentContactUrl }}">{{ contentContactName }}</a>
      </h3>

      <!-- Lesson Table and Card implementation -->
      <div class="row px-3" *ngIf="allLessons.length > 0">
        <app-lesson-details
          class="col-12 px-0 py-0"
          [allLessons]="allLessons"
          [lessonsSource]="currentLessonsSource"
          [showCards]="showCards"
          [referenceCacheData]="commonService.referenceConfig"
          [fieldConfigCore]="fieldConfigCore"
          [selectedTabIndex]="selectedTabIndex.value"
          [searchFilters]="searchOptions"
          [searchFacets]="facets"
          (addremovefavoritedeleteLesson)="searchLessons()"
        >
        </app-lesson-details>
      </div>
    </div>
  </div>
</main>

<!-- End Main Content -->
