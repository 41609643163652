import { Reference } from "../reference";

export class CrossReferenceConfigModel {
  BusinessUnitRegion: BusinessUnitRegionPair[];
  CopCopSubGroup: CopSubGroupPair[];
  SystemSubSystem: SystemSubSystemPair[];
  DisciplineSubDiscipline: DisciplineSubDisciplinePair[];
  BlockArea: BlockAreaPair[];
  AreaFacility: AreaFacilityPair[];
  BusinessUnitShoreType: BusinessUnitShoreTypePair[];
}

export class AreaFacilityPair {
  area: string;
  facility: string;
}

export class BlockAreaPair {
  block: string;
  area: string;
}

export class BusinessUnitRegionPair {
  region: string;
  businessUnit: string;
}

export class SystemSubSystemPair {
  level5A: string;
  level5_1: string;
}

export class CopSubGroupPair {
  cop: string;
  copSubGroup: string;
}

export class DisciplineSubDisciplinePair {
  discipline: string;
  subDiscipline: string;
}

export class BusinessUnitShoreTypePair {
  shoreType: string;
  businessUnit: string;
}

export class DynamicFieldList {
  fieldToDictateForCrossReferenceConfig: string; //the field that is going to be dictated
  reference: Reference[];
}
