import { Injectable } from "@angular/core";
import {
  Lesson,
  LessonFieldUpdate,
  Reference,
  User,
} from "src/app/shared/models";
import { WorkflowConfig } from "src/app/shared/models/configsV2/workflowConfigModel";
import { ReferenceConfigModel } from "src/app/shared/models/configsV2/referenceConfigModel";
import {
  FieldConfigModel,
  Workflow,
  AttributeCore,
} from "src/app/shared/models/configsV2/fieldConfigModel";
import {
  AccessOnCertainRoles,
  FromStatusFieldConfigModel,
  WorkflowModel,
} from "src/app/shared/models/configsV2/fromStatusFieldConfigModel";
import { UserService } from "../../http/user.service";
import { Router } from "@angular/router";
import { CrossReferenceConfigModel } from "src/app/shared/models/configsV2/crossReferenceConfigModel";
import {
  DataTypeConstants,
  FieldConfigStatusValues,
  Statuses,
} from "src/app/config/global-enums.config";
import { environment } from "src/environments/environment";
import { MsGraphService } from "../../http/ms-graph.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SetConfigService } from "../set-config.service";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { GraphUserCache } from "src/app/shared/models/graph-users-groups";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from "rxjs/operators";
import { CrossVisibilityFieldConfigModel } from "src/app/shared/models/configsV2/crossVisibilityFieldConfigModel";
import { bulkEditConfigProperties } from "src/app/shared/models/configsV2/bulkEditConfigModel";
import { ExtensionType } from "src/app/config/global-enums.config";
import { CreateEditProjectLookbackService } from "../project-lookback-services/create-edit-project-lookback.service";
import { ProjectLookbackService } from "../../http/project-lookback.service";
import { ToastService } from "../toast.service";

@Injectable({ providedIn: "root" })
export class CommonService {
  private grpEnv = environment.groupEnv;
  crossReferenceConfig = new CrossReferenceConfigModel();
  workflowConfig: WorkflowConfig[] = [];
  referenceConfig = new ReferenceConfigModel();
  fieldConfig: FieldConfigModel;
  fromStatusFieldConfig: FromStatusFieldConfigModel;
  crossVisibilityFieldConfig = new CrossVisibilityFieldConfigModel();
  public bulkEditConfig: bulkEditConfigProperties;

  fieldConfigGroups: Array<{ key: string; value: any }>;
  fieldConfigurations: any;
  editableFieldsByStatus: Array<{ key: string; value: string[] }>;
  visibleFieldsByStatus: Array<{ key: string; value: string[] }>;
  requiredFieldsByStatus: Array<{ key: string; value: string[] }>;
  readOnlyFieldsByStatus: Array<{ key: string; value: string[] }>;
  visibleFieldItems: any;

  allReferenceConfigGraphGroupsCache: GraphUserCache = {};

  //crossReference config fields (these are dependant dropdowns)
  subDisciplineReferencesfromCrossReferenceConfig: Reference[] = [];

  //For loading auto complete list of users
  userAsyncList: Observable<User[]>;
  private searchText$ = new Subject<string>();

  userProfile: User;
  references: any;
  showPOCLoading = false;
  

  constructor(
    private userService: UserService,
    private router: Router,
    private graphapi: MsGraphService,
    private setConfigService: SetConfigService,
    private projectLookbackService: CreateEditProjectLookbackService,
    private projectLookbackApiService: ProjectLookbackService,
    private toastService: ToastService
  ) { }

  public loadConfig() {
    try {
      this.userProfile = JSON.parse(sessionStorage.getItem("profile-ell"));
      this.workflowConfig = JSON.parse(localStorage.getItem("workflowConfig"));
      this.crossReferenceConfig = JSON.parse(
        localStorage.getItem("crossReferenceConfig")
      );
      this.fieldConfig = JSON.parse(localStorage.getItem("fieldConfig"));
      this.referenceConfig = JSON.parse(localStorage.getItem("referenceConfig"));
      this.fromStatusFieldConfig = JSON.parse(
        localStorage.getItem("fromStatusFieldConfig")
      );
      this.crossVisibilityFieldConfig = JSON.parse(
        localStorage.getItem("crossVisibilityFieldConfig")
      );
      this.bulkEditConfig = JSON.parse(localStorage.getItem("bulkEditConfig"));

      //To load Graph Users for POC field
      this.loadGraphUsersAsync();
      this.loadReferenceConfig();
    }
    catch{
      this.toastService.showRetrievingMessage(true, 'Please give us a moment as we finish setting up some things from our end.');
    }
  }


  public loadReferenceConfig(){
    let coreReferences = this.referenceConfig.core;
    this.references = Object.assign(coreReferences);
  }

  public loadGroups() {
    const core = this.fieldConfig.fieldConfig.lessonCore;
    this.fieldConfigGroups = this.readFieldConfiguration(core);
    return this.fieldConfigGroups;
  }

  private readFieldConfiguration(groupType: Workflow) {
    let groupObject = new Array<{ key: string; value: AttributeCore[] }>();
    for (const item in groupType) {
      const arr = [];
      const obj = groupType[item];
      const group = item;
      for (const attribute in obj) {
        const key = attribute;
        const attributeValue = obj[attribute];
        arr.push({ key: key, value: attributeValue });
      }
      groupObject.push({ key: group, value: arr });
    }
    return groupObject;
  }

  public loadFieldConfigurations() {
    const coreFieldsConfig = this.fieldConfig.fieldConfig.lessonCore;
    this.fieldConfigurations = Object.assign(coreFieldsConfig);
  }

  public convertDisciplineCode(discCode: string){
    let disciplines = this.referenceConfig;
  }
  public async loadFieldsStatus(lesson: Lesson) {
    let statusList;
    if(lesson.lessonWorkflowType !== ExtensionType.plbLessonWorkflow){
        statusList =
        this.fromStatusFieldConfig.fromStatusFieldConfig.extension[
        lesson.lessonWorkflowType
        ];
    }
    else{ 
      if(lesson.projectCodes.length > 0){
        const res = await firstValueFrom(this.projectLookbackApiService.getProjectLookback(lesson.projectCodes[0]));
        statusList = this.fromStatusFieldConfig.fromStatusFieldConfig.extension[res.projectLookbacks[0].lessonExtensionType];
      }
      else{
        statusList =
        this.fromStatusFieldConfig.fromStatusFieldConfig.extension[
        this.projectLookbackService.projectLookback.lessonExtensionType
        ];
      }

    }
    this.editableFieldsByStatus = this.getFieldList(
      statusList,
      FieldConfigStatusValues.editable,
      lesson
    );
    this.requiredFieldsByStatus = this.getFieldList(
      statusList,
      FieldConfigStatusValues.required,
      lesson
    );
    this.readOnlyFieldsByStatus = this.getFieldList(
      statusList,
      FieldConfigStatusValues.readOnly,
      lesson
    );
    this.visibleFieldsByStatus = this.getAllVisibleFiledsByStatus(
      this.readOnlyFieldsByStatus,
      this.editableFieldsByStatus
    );
  }

  public getFieldList(fields: WorkflowModel, fName: string, lesson: Lesson) {
    const groupObject = new Array<{ key: string; value: string[] }>();
    for (let status in Statuses) {
      const statusKey = Statuses[status];
      let arr = [];
      for (const fieldItem in fields) {
        const fieldKey = fieldItem;
        const fieldModelBehaviour = fields[fieldItem];
        for (let behaviour in fieldModelBehaviour) {
          const attributeValue = fieldModelBehaviour[behaviour];
          let ifStatusExists = attributeValue["statuses"].find(
            (x) => x == statusKey
          );
          if (ifStatusExists) {
            if (behaviour === fName) {
              if (
                fName === FieldConfigStatusValues.readOnly ||
                fName === FieldConfigStatusValues.editable
              ) {
                arr = this.updateFieldListForStatus(
                  fName,
                  attributeValue,
                  lesson,
                  arr,
                  fieldKey,
                  statusKey
                );
              } else if (
                fName === FieldConfigStatusValues.required ||
                fName === FieldConfigStatusValues.exportable
              ) {
                arr.push(fieldKey);
              }
            }
          }
        }
      }
      groupObject.push({ key: statusKey, value: arr });
    }
    return groupObject;
  }

  updateFieldListForStatus(
    fName: string,
    attributeValue: any,
    lesson: Lesson,
    arr: any,
    fieldKey: string,
    statusKey: string
  ) {
    let ifNotNull: any;
    let onCertainRoles: any;
    let ifParticipant: any;
    if (fName === FieldConfigStatusValues.readOnly) {
      ifNotNull = attributeValue["readOnlyIfNotNull"];
      onCertainRoles = attributeValue["readOnlyOnCertainRoles"];
      ifParticipant = attributeValue["readOnlyIfParticipant"];
    } else if (fName === FieldConfigStatusValues.editable) {
      ifNotNull = null;
      onCertainRoles = attributeValue["editableOnCertainRoles"];
      ifParticipant = attributeValue["editableIfParticipant"];
    }
    if (ifNotNull) {
      if (this.setConfigService.isNotNull(lesson[ifNotNull.Param])) {
        arr.push(fieldKey);
      }
    } else if (ifParticipant) {
      if (
        ifParticipant == true &&
        this.setConfigService.isParticipantOfLesson(lesson)
      ) {
        arr.push(fieldKey);
      }
    } else if (onCertainRoles) {

      let fcm = onCertainRoles as AccessOnCertainRoles;
      if (fcm.status.indexOf(statusKey) != -1) {
        for (let index = 0; index < fcm.roles.length; index++) {
          const element = fcm.roles[index];
          if (this.setConfigService.certainRoles(element)) {
            arr.push(fieldKey);
            break;
          }
        }
      } else {
        arr.push(fieldKey);
      }

    } else {
      arr.push(fieldKey);
    }
    return arr;
  }

  getAllVisibleFiledsByStatus(editableFieldsByStatus, readOnlyFieldByStatus) {
    const visibleFieldByStatus = new Array<{ key: string; value: string[] }>();
    for (let status in Statuses) {
      let statusKey = Statuses[status];
      let arrReadOnlyFields = readOnlyFieldByStatus.find(
        (x) => x.key === statusKey
      ).value;
      let arrEditableFields = editableFieldsByStatus.find(
        (x) => x.key === statusKey
      ).value;
      let arrVisibleFields = arrReadOnlyFields.concat(arrEditableFields);
      visibleFieldByStatus.push({ key: statusKey, value: arrVisibleFields });
    }
    return visibleFieldByStatus;
  }

  public isFieldEditable(
    isLessonEditable: boolean,
    status: string,
    fieldName: string
  ): boolean {
    if (this.editableFieldsByStatus && fieldName && status) {
      if (isLessonEditable) {
        const fieldList = this.editableFieldsByStatus.find(
          (x) => x.key === status
        ).value;
        return fieldList?.includes(fieldName);
      }
    }
    return false;
  }

  public isFieldVisible(status: string, fieldName: string): boolean {
    if (this.visibleFieldsByStatus && status && fieldName) {
      const fieldList = this.visibleFieldsByStatus.find(
        (x) => x.key === status
      ).value;
      return fieldList?.includes(fieldName);
    }
    return false;
  }

  public isFieldRequired(status: string, fieldName: string): string {
    if (this.requiredFieldsByStatus && status && fieldName) {
      const fieldList = this.requiredFieldsByStatus.find(
        (x) => x.key === status
      ).value;
      return fieldList?.includes(fieldName)
        ? FieldConfigStatusValues.required
        : "";
    }
    return "";
  }

  public isfRequired(status: string, fieldName: string): boolean {
    if (this.requiredFieldsByStatus && status && fieldName) {
      const fieldList = this.requiredFieldsByStatus.find(
        (x) => x.key === status
      ).value;
      return fieldList?.includes(fieldName);
    }
    return false;
  }

  public loadVisibleItemGroupValues(status: string) {
    this.visibleFieldItems = new Array<{
      key: string;
      value: AttributeCore[];
    }>();
    this.fieldConfigGroups.forEach((i) => {
      const arr = [];
      i.value.forEach((j) => {
        if (this.isFieldVisible(status, j.key)) {
          arr.push({ key: j.key, value: j.value });
        }
      });
      if (arr.length > 0) {
        this.visibleFieldItems.push({ key: i.key, value: arr });
      }
    });
  }


  getReferenceConfigsByReferenceConfigEnumFromFieldConfig(
    group: string,
    field: string,
    lessonWorkflowType: string
  ): Reference[] {
    const enumName =
      this.fieldConfigurations[group][field].feMetadata.referenceConfigEnum;
    if (enumName) {
      let list = this.references[enumName];
      if (list && list[0].lessonWorkflowTypeTag) {
        list = list.filter((x) =>
          x.lessonWorkflowTypeTag.includes(lessonWorkflowType)
        );
      }
      return list;
    }
    return null;
  }

  public getUsersFromDirectory(event) {
    const query = event.toString();
    if (query.length > 1) {
      this.showPOCLoading = true;
      this.searchText$.next(query);
    }
  }

  NavigateBackToHomePage() {
    this.router.navigate(["/home"]);
  }

  

  replaceTemplateGraphGroup(
    referenceConfigGraph: string,
    cop?: string,
    copSubgroup?: string,
    region?: string,
    discipline?: string,
    businessUnit?: string
  ) {
    /*
            SE-ELL-{ENV}-MGR-{COP} -> cop
            SE-ELL-{ENV}-TECHAPR-{COP}-{COPSUBGROUP} -> cop and subgroup
            SE-ELL-{ENV}-ECAPR-{REGION} -> ahead of time
            SE-ELL-{ENV}-IPAPR -> ahead of time
            SE-ELL-{ENV}-LGAPR -> ahead of time

            BUs:
            SE-BLL-{ENV}-TECHAPR-GOM-FE-{DISCIPLINE} -> discipline
            SE-BLL-{ENV}-MGR-GOM-FE -> setvalue in evalConfig

            */
    if (referenceConfigGraph) {
      let key = referenceConfigGraph
        .replace("{ENV}", this.grpEnv)
        .replace("{COP}", cop ?? "{}")
        .replace("{REGION}", region?.toUpperCase() ?? "{}")
        .replace("{BU}", businessUnit?.toUpperCase() ?? "{}")
        .replace("{DISCIPLINE}", discipline?.toUpperCase() ?? "{}");

      if (copSubgroup) {
        key = key.replace("{COPSUBGROUP}", copSubgroup);
      }
      key = key.replace("-{COPSUBGROUP}", "");
      return key;
    }
  }

  convertLessonWorkflowToCode(lessonWorkflowType: string){
    switch(lessonWorkflowType){
      case "abuFEWorkflow":
        return "ABU";
      case "gombuFEWorkflow":
        return "GOMBU";
      case "tcoFEWorkflow":
        return "EBU";
      case "sasbuFEWorkflow":
        return "SASBU";
      case "singaporeFEWorkflow":
        return "Singapore";
      case "mauaFEWorkflow":
        return "Maua";
      case "gonfrevilleFEWorkflow":
        return "Gonfreville";
      case "oakpointFEWorkflow":
        return "Oakpoint";
      case "bbuFEWorkflow":
        return "BBU";
      case "rbuFEWorkflow":
        return "RBU";
      case "cppFEWorkflow":
        return "CPP";
      case "wellsWorkflow":
        return "Wells";
    }

  }

  getRoleClaims(role: string) {
    let roleClaimName = "ELL-" + role;
    return roleClaimName;
  }

  async getAuthorizedApprovers(groupName): Promise<User[]> {
    let approvers = await this.getUsersFromGraph(groupName);
    return approvers;
  }

  async getGraphUsersByLessonWorkflowType(
    referenceConfigGraph: string,
    lessonWorkflowType: string,
    cop?: string,
    copSubgroup?: string,
    region?: string,
    discipline?: string,
    businessUnit?: string
  ): Promise<User[]> {


    let groupName = this.replaceTemplateGraphGroup(
      referenceConfigGraph[lessonWorkflowType],
      cop,
      copSubgroup,
      region,
      discipline,
      businessUnit
    );

    if (!(groupName in this.allReferenceConfigGraphGroupsCache)) {
      //if the group name is not in dict: call and populate group with graph
      this.allReferenceConfigGraphGroupsCache[groupName] =
        await this.getUsersFromGraph(groupName);
    }
    return this.allReferenceConfigGraphGroupsCache[groupName];
  }

  async loadUsersApiByFilters(
    discipline?: string,
    businessUnit?: string,
    role?: string
  ){

    let usersApi: User[] = [];
    usersApi = await firstValueFrom(
      this.userService.getUserByFilters(
        businessUnit, role
      )
    )
    return usersApi;
  }

  async loadGraphUsersByLessonWorkflowType(
    cop?: string,
    copSubgroup?: string,
    region?: string,
    discipline?: string,
    businessUnit?: string
  ): Promise<void> {
    /*
        the purpose of this method is to:
         - load graph groups ahead of time if their template coming from referenceConfigGraph is valid.
         - we determine if a template is valid if it DOES NOT contain a "{}"
         - If the referenceConfigGraph template has been succesfully replaced with the correct value, then graph.service is called.
        */

    //Step 1: Get all possible referenceConfigGraph properties from fieldConfig (from all fields)
    let refConfigGraphObjs = [];
    for (let g of Object.keys(this.fieldConfig.fieldConfig.lessonCore)) {
      Object.values(this.fieldConfig.fieldConfig.lessonCore[g]).forEach((f) => {
        refConfigGraphObjs.push(f["feMetadata"]["referenceConfigGraph"]);
      });
    }
    /*
         Setp 2: Filter out undefined and flatten out referenceConfigGraph templates. 
         Ex.  "referenceConfigGraph": {
              "copWorkflow": "SE-ELL-{ENV}-MGR-{COP}"
            }  
            
            ---> "SE-ELL-{ENV}-MGR-{COP}"

        */
    let listOfAllReferenceConfigGraph = refConfigGraphObjs
      .filter(Boolean)
      .flatMap((val) => {
        let personaReferenceConfigGraphObj = [];
        for (const [key, value] of Object.entries(val)) {
          personaReferenceConfigGraphObj.push(value);
        }
        return personaReferenceConfigGraphObj;
      });

    //Step 3: replace templates if possible with lesson attributes
    listOfAllReferenceConfigGraph.forEach((referenceConfigGraph, index) => {
      let groupName = this.replaceTemplateGraphGroup(
        referenceConfigGraph,
        cop,
        copSubgroup,
        region,
        discipline,
        businessUnit
      );
      listOfAllReferenceConfigGraph[index] = groupName;
    });

    //Step 4: Filter out templates that are not ready to be called to graph, if it contains a {} still, then graph can't be called yet.
    let filteredGraphGroupsToCall = listOfAllReferenceConfigGraph.filter(
      (refConfigGraph) => {
        return !refConfigGraph.includes("{}");
      }
    );

    //Step 5: Get Users from Graph groups, if Group with Users does not exist already. NOte: It is ok if templates are repeated as they will not be called.
    for (let groupName of filteredGraphGroupsToCall) {
      if (!(groupName in this.allReferenceConfigGraphGroupsCache)) {
        this.allReferenceConfigGraphGroupsCache[groupName] =
          await this.getUsersFromGraph(groupName);
      }
    }
  }

  async getUsersFromGraph(groupName): Promise<User[]> {
    let usersGraph: User[] = [];
    await firstValueFrom(
      this.graphapi.getAADGroupId(groupName).pipe(
        map(async (data) => {
          if (data && data.value.length > 0) {
            let groupId = data.value[0].id;
            await firstValueFrom(
              this.graphapi.getAADGroupMembersFromGraph(groupId).pipe(
                map((data) => {
                  let arrayItem = data.value;
                  arrayItem.forEach((users) => {
                    let user = new User();
                    user.fullName = users.displayName;
                    user.email = users.userPrincipalName;
                    user.uniqueKey = users.userPrincipalName.toLowerCase();
                    usersGraph.push(user);
                  });
                })
              )
            );
          }
        })
      )
    );
    return usersGraph;
  }

  public getLessonFieldValueInstruction(fieldId: string) {
    //checking in core
    for (const group in this.fieldConfig.fieldConfig.lessonCore) {
      if (this.fieldConfig.fieldConfig.lessonCore[group][fieldId]) {
        return this.fieldConfig.fieldConfig.lessonCore[group][fieldId].feMetadata
          ?.instruction;
      }
    }
  }

  public getLessonForm(
    lessonStatus: string,
    lesson?: Lesson,
    visibleFieldItems?: any
  ): FormGroup {
    const llFormGroup = {};
    const fieldItems = visibleFieldItems
      ? visibleFieldItems
      : this.visibleFieldItems;
    let value: any;
    if (fieldItems) {
      fieldItems.forEach((group) => {
        group.value.forEach((item) => {
          if (item.value.feMetadata.dataType == DataTypeConstants.string) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : "",
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.stringArray
          ) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : [],
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.userObj
          ) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : User,
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (item.value.feMetadata.dataType == DataTypeConstants.date) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : Date,
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.number
          ) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : Number,
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.boolean
          ) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : Boolean,
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType == DataTypeConstants.userArray
          ) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : [],
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          } else if (
            item.value.feMetadata.dataType ==
            DataTypeConstants.lessonCommentArray
          ) {
            value = this.getLessonFieldValue(lesson, item.key);
            llFormGroup[item.key] = new FormControl(
              value ? value : [],
              this.isfRequired(lessonStatus, item.key)
                ? Validators.required
                : Validators.nullValidator
            );
          }
        });
      });
    }
    return new FormGroup(llFormGroup);
  }

  public getPendingStatus(
    selectedTransitionName: any,
    extensionType: string,
    lessonStatus: string
  ) {
    //get the pendingStatus on the basis of transitionName
    let workflowElement = this.workflowConfig[extensionType]?.find(
      (wf) =>
        wf.FromStatus == lessonStatus &&
        wf.TransitionName == selectedTransitionName
    );
    return workflowElement?.ToStatus;
  }

  getGroupDescription(key: string) {
    let groups = this.references["GroupReferences"];
    let description =
      groups.find((x) => x.Code == key) != undefined
        ? groups.find((x) => x.Code == key).Description
        : key;
    return description;
  }

  public getFieldSelectValues(
    tempLesson: LessonFieldUpdate,
    fieldToDictate: string,
    groupName: string,
    fieldList: Reference[]
  ): Reference[] {
    let crossReferenceConfig =
      this.fieldConfig.fieldConfig.lessonCore[groupName][tempLesson.fieldId]
        ?.feMetadata?.crossReferenceConfigEnum;
    let referenceConfig =
      this.fieldConfig.fieldConfig.lessonCore[groupName][tempLesson.fieldId]
        ?.feMetadata?.referenceConfigEnum;

    if (crossReferenceConfig !== undefined) {
      let crossReferenceValuesPair = this.crossReferenceConfig[
        crossReferenceConfig
      ]?.filter(
        (crossReference) =>
          crossReference[fieldToDictate] === tempLesson.lesson[fieldToDictate]
      );

      let dictatedReferenceValues = this.referenceConfig.core[
        `${referenceConfig}`
      ]?.filter(
        (f) =>
          crossReferenceValuesPair.filter(
            (e) => e[tempLesson.fieldId] === f.Code
          ).length > 0
      );
      return dictatedReferenceValues;
    } else {
      return fieldList.sort((a, b) => {
        const descA = a.Description.toUpperCase();
        const descB = b.Description.toUpperCase();
        return descA.localeCompare(descB)
      });
    }
  }

  private loadGraphUsersAsync() {
    this.userAsyncList = this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((user) => {
        this.showPOCLoading = false;
        return this.userService.getUserFromChevronDirectory(user);
      })
    );
  }

  getLessonFieldValue(lesson: Lesson, field: string) {
    if (lesson) {
      if (lesson[field]) return lesson[field];
      else {
        if (lesson[lesson.lessonWorkflowType])
        {
          return lesson[lesson.lessonWorkflowType][field];
        }
          
      }
    }
    return null;
  }

  //Re-set default values for crossvisibility config fields
  public resetDefaultVaulesForCrossVisibility(
    fieldName: string,
    lesson: Lesson
  ) {
    this.fieldConfigGroups.forEach((group) => {
      group.value.forEach((y) => {
        if (
          y.key == fieldName &&
          y.value.feMetadata.defaultValue != undefined
        ) {
          lesson[fieldName] = y.value.feMetadata.defaultValue;
        }
      });
    });
  }
}

interface WorkflowStatusEntry {
  FromStatus: string;
  ToStatus: string,
  TransitionName: string;
}