import { Inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, ReplaySubject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Cop } from "../../shared/models/cop";
import { User } from "../../shared/models";
import { UserCopBusinessUnit } from "../../shared/models/user-trigger-business-unit";

@Injectable({
  providedIn: "root",
})
export class UserCopBusinessUnitService {

    constructor(private http: HttpClient) {}

    apiRoot = `${environment.apiUrl}UserCopBusinessUnit`;

    public getAllUsersByCopId(copId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByCopId/${copId}`);
    }

    public getAllUsersByBusinessUnit(businessUnit: string): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnit/${businessUnit}`);
    }

    public getAllUsersByBuId(buId: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByBusinessUnitId/${buId}`);
    }

    public getAllCopApprovers(userId: number): Observable<Cop[]> {
      return this.http.get<Cop[]>(`${this.apiRoot}/getAllCopApprovers/${userId}`);
    }

    public getAllCopApproversByUniqueKey(uniqueKey: string): Observable<Cop[]> {
      return this.http.get<Cop[]>(`${this.apiRoot}/getAllCopApproversByUniqueKey/${uniqueKey}`);
    }

    public getAllUsers(id: number): Observable<User[]> {
      return this.http.get<User[]>(`${this.apiRoot}/getAllUsersByTeamId/${id}`);
    }

    public createUserCopBusinessUnit(triggerCode: string, uniqueKey: string, businessUnitCode: string): Observable<UserCopBusinessUnit>{
      const endPoint = `${this.apiRoot}/createUserCopBusinessUnit`
      const body = {triggerCode, uniqueKey, businessUnitCode};
      return this.http.post<UserCopBusinessUnit>(endPoint, body);
    }

    public deleteUserCopBusinessUnit(userId: number, copId: number, buId: number){
      const endPoint = `${this.apiRoot}/deleteCop?userId=${userId}&copId=${copId}&buId=${buId}`
      return this.http.delete(endPoint);
    }

}